import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Greeting from '../components/greeting';
import Grid from '@material-ui/core/Grid';
import David from '../images/david.jpg'

class IndexPage extends React.Component {

  render() {
    return (
        <Layout>
          <Helmet>
            <title>David Hildering - Freelance Front-end Developer</title>
            <meta name="description" content="Hi, My name is David Hildering. I am a freelance front-end developer from Utrecht."/>
          </Helmet>
          <Grid container
                className="link-list"
          >
            <Grid item xs={4}>
              <div>
                <Link to="/about/">About</Link>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Link to="/projects/">Projects</Link>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Link to="/contact/">Contact</Link>
              </div>
            </Grid>
          </Grid>
        <Grid container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={8}>
        <Grid item xs={12}>
        <div className='app-intro'>
        <Greeting />
          <img src={ David } className="profile"  alt="David Hildering" />
        <h2 className='inline'>DAVID HILDERING</h2>
    <h2>FRONT-END DEVELOPER</h2>
        </div>
        </Grid>
        </Grid>
        </Layout>
  );
  }
}

export default IndexPage;