import React from 'react';


class Greeting extends React.Component {
  constructor() {
    super();

    const today = new Date();
    const curHr = today.getHours()


    if (curHr < 12) {
      this.date = 'Good morning and Hello World!'
    } else if (curHr < 18) {
      this.date  = 'Good afternoon and Hello World!';
    } else if (curHr < 24) {
      this.date = 'Good evening and Hello World!';
    } else {
      this.date = 'Hi there! Still awake? Go to bed!';
    }

    this.state = {
      date: this.date
    };
  }


  render() {
    return (
        <h1>{this.state.date}</h1>
    );
  }
}

export default Greeting;